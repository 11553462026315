<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1">
            転職コレクション
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          ようこそ、転コレへ！
        </b-card-title>
        <b-card-text class="mb-2">
          <div class="">
            下記のアドレスにメールを送信しました。
          </div>
          <div class="mailaddresText_ text-center text-primary">
            {{ mailaddress }}
          </div>
          <div class="">
            メールに記載されおります４桁の確認番号を入力して｢確認する｣ボタンを押して下さい。
          </div>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="verifyNumber"
              label="確認番号"
            >
              <validation-provider
                name="確認番号"
              >
                <b-form-input
                  id="verifyNumber"
                  v-model="verifyNumber"
                  class="input_"
                  name="login-email"
                  placeholder="1234"
                  autofocus
                  type="number"
                />
                <small class="text-danger">{{ errorMSG }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-if="status == 1"
              variant="primary"
              block
              :disabled="invalid"
              style="margin: 10% 0;"
              @click="confirm"
            >
              確認する
            </b-button>
            <div
              v-if="status == 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import verifyNumbers from '@/firestore/authentication/verifyNumber'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // VuexyLogo,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      errorMSG: '',
      required,
      email,
      status: 1,
      verifyNumber: null,
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['mailaddress']),
  },
  mounted() {
  },
  methods: {
    async confirm() {
      this.status = 2
      const data = {
        mailaddress: this.mailaddress,
        verifyNumber: this.verifyNumber,
      }
      const response = await verifyNumbers(data)
      if (response.status === 'success') {
        // window.console.log('🐶', response.status)
        this.$store.dispatch('verifyEmail/updateVerifyEmailStatus', 3)
      } else {
        this.errorMSG = response.msg
        this.status = 1
        // window.console.log('error', response.msg)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner_ {
  margin: 3% auto;
}
.mailaddresText_ {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10% 0;
}
.input_ {
  font-size: 1.4rem;
  letter-spacing: 10px;
}
</style>
