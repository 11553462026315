import firebase from 'firebase/app'

export default async function verifyNumbers(payload) {
  const { mailaddress, verifyNumber } = payload
  try {
    let data = {}
    await firebase.firestore().collection('guest')
      .where('mailaddress', '==', mailaddress)
      .get()
      .then(sn => {
        if (!sn.empty) {
          sn.forEach(doc => {
            data = doc.data()
          })
        }
      })

    if (Number(data.verifyNumber) === Number(verifyNumber)) {
      const response = {
        status: 'success',
      }
      return response
    }
    const response = {
      status: 'error',
      msg: '確認番号が正しくありません。',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
